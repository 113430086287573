<template>
  <div class="chart-monitor-container">
    <div class="chart-content">
      <Form :label-width="85" label-position="right" class="search-one">
        <Row :gutter="16">
          <Col span="7">
            <Date-picker @on-change="changeTimeOne" type="daterange" placeholder="选择日期"></Date-picker>
          </Col>
          <Col span="5">
            <Form-item label="顾客类型：">
              <Cascader :data="mtypeone" v-model="mtypeArrOne"></Cascader>
            </Form-item>
          </Col>
          <Col span="2">
            <Button type="primary" class="search-btn" @click="handleOne()">搜索</Button>
          </Col>
        </Row>
      </Form>
      <div class="chart" ref="barData"></div>
    </div>
    <div class="chart-content">
      <Form :label-width="85" label-position="right">
        <Row>
          <Col span="6">
            <Date-picker @on-change="changeTimeTwo" v-model="timeTwo" :clearable="false" type="daterange" placeholder="选择日期"></Date-picker>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="项目：">
              <Select v-model="searchTwo.type" placeholder="请选择">
                <Option :value="v.name" v-for="v in typeList" :key="v.name">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="顾客类型：">
              <Cascader :data="mtypetwo" v-model="mtypeArrTwo"></Cascader>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="6">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchTwo.pid" placeholder="请选择" @on-change="changeProTwo" v-else>
                <Option :value="v.id" v-for="v in proListTwo" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchTwo.cid" placeholder="请选择" @on-change="changeCityTwo" v-else>
                <Option :value="v.id" v-for="v in cityListTwo" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchTwo.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeListTwo" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="2">
            <Button type="primary" class="search-btn" @click="handleTwo()">搜索</Button>
          </Col>
        </Row>
      </Form>
      <div class="card-wrapper">
        <Row :gutter="40">
          <Col span="8">
            <div class="card-module-nofoot bg1">
              <Row>
                <Col span="12">
                  <Row type="flex" justify="center" align="middle">
                    <Col span="8"><img src="@/assets/img/icon-1.png" alt="" width="40px" height="40px"></Col>
                    <Col span="16">
                      <p v-show="typeTwo === '收缩压'" :title="'收缩压最低值' + min + 'mmHg'"><em class="font_30">{{min}}</em>mmHg</p>
                      <p v-show="typeTwo === '舒张压'" :title="'舒张压最低值' + min + 'mmHg'"><em class="font_30">{{min}}</em>mmHg</p>
                      <p v-show="typeTwo === '心率'" :title="'心率最低值' + min + '次/分'"><em class="font_30">{{min}}</em>次/分</p>
                      <p v-show="typeTwo === '空腹血糖'" :title="'空腹血糖最低值' + min + 'mmol/L'"><em class="font_30">{{min}}</em>mmol/L</p>
                      <p v-show="typeTwo === '饭后半小时'" :title="'饭后半小时最低值' + min + 'mmol/L'"><em class="font_30">{{min}}</em>mmol/L</p>
                      <p v-show="typeTwo === '饭后1小时'" :title="'饭后1小时最低值' + min + 'mmol/L'"><em class="font_30">{{min}}</em>mmol/L</p>
                      <p v-show="typeTwo === '饭后2小时'" :title="'饭后2小时最低值' + min + 'mmol/L'"><em class="font_30">{{min}}</em>mmol/L</p>
                      <p v-show="typeTwo === '饭后3小时'" :title="'饭后3小时最低值' + min + 'mmol/L'"><em class="font_30">{{min}}</em>mmol/L</p>
                      <p v-show="typeTwo === '尿酸值'" :title="'尿酸值最低值' + min + 'mmol/L'"><em class="font_30">{{min}}</em>mmol/L</p>
                      <p v-show="typeTwo === '总胆固醇'" :title="'总胆固醇最低值' + min + 'mmol/L'"><em class="font_30">{{min}}</em>mmol/L</p>
                      <p v-show="typeTwo === '甘油三脂'" :title="'甘油三脂最低值' + min + 'mmol/L'"><em class="font_30">{{min}}</em>mmol/L</p>
                      <p v-show="typeTwo === '高密度脂蛋白'" :title="'高密度脂蛋白最低值' + min + 'mmol/L'"><em class="font_30">{{min}}</em>mmol/L</p>
                      <p v-show="typeTwo === '低密度脂蛋白'" :title="'低密度脂蛋白最低值' + min + 'mmol/L'"><em class="font_30">{{min}}</em>mmol/L</p>
                      <p v-show="typeTwo === '身高'" :title="'身高最低值' + min + 'cm'"><em class="font_30">{{min}}</em>cm</p>
                      <p v-show="typeTwo === '腰围'" :title="'腰围最低值' + min + 'cm'"><em class="font_30">{{min}}</em>cm</p>
                      <p v-show="typeTwo === '体重'" :title="'体重最低值' + min + 'kg'"><em class="font_30">{{min}}</em>kg</p>
                      <p v-show="typeTwo === '体格指数'" :title="'体格指数最低值' + min"><em class="font_30">{{min}}</em></p>
                      <p v-show="typeTwo === '体脂肪率'" :title="'体脂肪率最低值' + min + '%'"><em class="font_30">{{min}}</em>%</p>
                      <p v-show="typeTwo === '内脂指数'" :title="'内脂指数最低值' + min"><em class="font_30">{{min}}</em></p>
                      <p v-show="typeTwo === '体水分率'" :title="'体水分率最低值' + min + '%'"><em class="font_30">{{min}}</em>%</p>
                      <p v-show="typeTwo === '骨骼肌率'" :title="'骨骼肌率最低值' + min + '%'"><em class="font_30">{{min}}</em>%</p>
                      <p v-show="typeTwo === '骨矿含量'" :title="'骨矿含量最低值' + min + '%'"><em class="font_30">{{min}}</em>kg</p>
                      <p v-show="typeTwo === '基础代谢'" :title="'基础代谢最低值' + min + 'kcal'"><em class="font_30">{{min}}</em>kcal</p>
                      <p v-show="typeTwo === '握力左'" :title="'握力左最低值' + min + 'kg'"><em class="font_30">{{min}}</em>kg</p>
                      <p v-show="typeTwo === '握力右'" :title="'握力右最低值' + min + 'kg'"><em class="font_30">{{min}}</em>kg</p>
                      <p v-show="typeTwo === '肺活量'" :title="'肺活量最低值' + min + 'ml'"><em class="font_30">{{min}}</em>ml</p>
                      <p v-show="typeTwo === '睁眼平衡'" :title="'睁眼平衡最低值' + min + 's'"><em class="font_30">{{min}}</em>s</p>
                      <p v-show="typeTwo === '闭眼平衡'" :title="'闭眼平衡最低值' + min + 's'"><em class="font_30">{{min}}</em>s</p>
                      <h2 :title="typeTwo + '最低值'">{{typeTwo}}最低值</h2>
                    </Col>
                  </Row>
                </Col>
                <Col span="12">
                  <Row type="flex" justify="center" align="middle">
                    <Col span="8"><img src="@/assets/img/icon-2.png" alt="" width="40px" height="40px"></Col>
                    <Col span="16">
                      <p v-show="typeTwo === '收缩压'" :title="'收缩压最高值' + max + 'mmHg'"><em class="font_30">{{max}}</em>mmHg</p>
                      <p v-show="typeTwo === '舒张压'" :title="'舒张压最高值' + max + 'mmHg'"><em class="font_30">{{max}}</em>mmHg</p>
                      <p v-show="typeTwo === '心率'" :title="'心率最高值' + max + '次/分'"><em class="font_30">{{max}}</em>次/分</p>
                      <p v-show="typeTwo === '空腹血糖'" :title="'空腹血糖最高值' + max + 'mmol/L'"><em class="font_30">{{max}}</em>mmol/L</p>
                      <p v-show="typeTwo === '饭后半小时'" :title="'饭后半小时最高值' + max + 'mmol/L'"><em class="font_30">{{max}}</em>mmol/L</p>
                      <p v-show="typeTwo === '饭后1小时'" :title="'饭后1小时最高值' + max + 'mmol/L'"><em class="font_30">{{max}}</em>mmol/L</p>
                      <p v-show="typeTwo === '饭后2小时'" :title="'饭后2小时最高值' + max + 'mmol/L'"><em class="font_30">{{max}}</em>mmol/L</p>
                      <p v-show="typeTwo === '饭后3小时'" :title="'饭后3小时最高值' + max + 'mmol/L'"><em class="font_30">{{max}}</em>mmol/L</p>
                      <p v-show="typeTwo === '尿酸值'" :title="'尿酸值最高值' + max + 'mmol/L'"><em class="font_30">{{max}}</em>mmol/L</p>
                      <p v-show="typeTwo === '总胆固醇'" :title="'总胆固醇最高值' + max + 'mmol/L'"><em class="font_30">{{max}}</em>mmol/L</p>
                      <p v-show="typeTwo === '甘油三脂'" :title="'甘油三脂最高值' + max + 'mmol/L'"><em class="font_30">{{max}}</em>mmol/L</p>
                      <p v-show="typeTwo === '高密度脂蛋白'" :title="'高密度脂蛋白最高值' + max + 'mmol/L'"><em class="font_30">{{max}}</em>mmol/L</p>
                      <p v-show="typeTwo === '低密度脂蛋白'" :title="'低密度脂蛋白最高值' + max + 'mmol/L'"><em class="font_30">{{max}}</em>mmol/L</p>
                      <p v-show="typeTwo === '身高'" :title="'身高最高值' + max + 'cm'"><em class="font_30">{{max}}</em>cm</p>
                      <p v-show="typeTwo === '腰围'" :title="'腰围最高值' + max + 'cm'"><em class="font_30">{{max}}</em>cm</p>
                      <p v-show="typeTwo === '体重'" :title="'体重最高值' + max + 'kg'"><em class="font_30">{{max}}</em>kg</p>
                      <p v-show="typeTwo === '体格指数'" :title="'体格指数最高值' + max"><em class="font_30">{{max}}</em></p>
                      <p v-show="typeTwo === '体脂肪率'" :title="'体脂肪率最高值' + max + '%'"><em class="font_30">{{max}}</em>%</p>
                      <p v-show="typeTwo === '内脂指数'" :title="'内脂指数最高值' + max"><em class="font_30">{{max}}</em></p>
                      <p v-show="typeTwo === '体水分率'" :title="'体水分率最高值' + max + '%'"><em class="font_30">{{max}}</em>%</p>
                      <p v-show="typeTwo === '骨骼肌率'" :title="'骨骼肌率最高值' + max + '%'"><em class="font_30">{{max}}</em>%</p>
                      <p v-show="typeTwo === '骨矿含量'" :title="'骨矿含量最高值' + max + '%'"><em class="font_30">{{max}}</em>kg</p>
                      <p v-show="typeTwo === '基础代谢'" :title="'基础代谢最高值' + max + 'kcal'"><em class="font_30">{{max}}</em>kcal</p>
                      <p v-show="typeTwo === '握力左'" :title="'握力左最高值' + max + 'kg'"><em class="font_30">{{max}}</em>kg</p>
                      <p v-show="typeTwo === '握力右'" :title="'握力右最高值' + max + 'kg'"><em class="font_30">{{max}}</em>kg</p>
                      <p v-show="typeTwo === '肺活量'" :title="'肺活量最高值' + max + 'ml'"><em class="font_30">{{max}}</em>ml</p>
                      <p v-show="typeTwo === '睁眼平衡'" :title="'睁眼平衡最高值' + max + 's'"><em class="font_30">{{max}}</em>s</p>
                      <p v-show="typeTwo === '闭眼平衡'" :title="'闭眼平衡最高值' + max + 's'"><em class="font_30">{{max}}</em>s</p>
                      <h2 :title="typeTwo + '最高值'">{{typeTwo}}最高值</h2>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span="8">
            <div class="card-module-extra bg2">
              <h2 v-show="typeTwo === '收缩压'" :title="'收缩压平均值' + average + 'mmHg'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmHg</h2>
              <h2 v-show="typeTwo === '舒张压'" :title="'舒张压平均值' + average + 'mmHg'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmHg</h2>
              <h2 v-show="typeTwo === '心率'" :title="'心率平均值' + average + '次/分'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>次/分</h2>
              <h2 v-show="typeTwo === '空腹血糖'" :title="'空腹血糖平均值' + average + 'mmol/L'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '饭后半小时'" :title="'饭后半小时平均值' + average + 'mmol/L'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '饭后1小时'" :title="'饭后1小时平均值' + average + 'mmol/L'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '饭后2小时'" :title="'饭后2小时平均值' + average + 'mmol/L'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '饭后3小时'" :title="'饭后3小时平均值' + average + 'mmol/L'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '尿酸值'" :title="'尿酸值平均值' + average + 'mmol/L'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '总胆固醇'" :title="'总胆固醇平均值' + average + 'mmol/L'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '甘油三脂'" :title="'甘油三脂平均值' + average + 'mmol/L'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '高密度脂蛋白'" :title="'高密度脂蛋白平均值' + average + 'mmol/L'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '低密度脂蛋白'" :title="'低密度脂蛋白平均值' + average + 'mmol/L'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '身高'" :title="'身高平均值' + average + 'cm'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>cm</h2>
              <h2 v-show="typeTwo === '腰围'" :title="'腰围平均值' + average + 'cm'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>cm</h2>
              <h2 v-show="typeTwo === '体重'" :title="'体重平均值' + average + 'kg'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>kg</h2>
              <h2 v-show="typeTwo === '体格指数'" :title="'体格指数平均值' + average">{{typeTwo}}平均值 <em class="font_30">{{average}}</em></h2>
              <h2 v-show="typeTwo === '体脂肪率'" :title="'体脂肪率平均值' + average + '%'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>%</h2>
              <h2 v-show="typeTwo === '内脂指数'" :title="'内脂指数平均值' + average">{{typeTwo}}平均值 <em class="font_30">{{average}}</em></h2>
              <h2 v-show="typeTwo === '体水分率'" :title="'体水分率平均值' + average + '%'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>%</h2>
              <h2 v-show="typeTwo === '骨骼肌率'" :title="'骨骼肌率平均值' + average + '%'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>%</h2>
              <h2 v-show="typeTwo === '骨矿含量'" :title="'骨矿含量平均值' + average + '%'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>kg</h2>
              <h2 v-show="typeTwo === '基础代谢'" :title="'基础代谢平均值' + average + 'kcal'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>kcal</h2>
              <h2 v-show="typeTwo === '握力左'" :title="'握力左平均值' + average + 'kg'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>kg</h2>
              <h2 v-show="typeTwo === '握力右'" :title="'握力右平均值' + average + 'kg'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>kg</h2>
              <h2 v-show="typeTwo === '肺活量'" :title="'肺活量平均值' + average + 'ml'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>ml</h2>
              <h2 v-show="typeTwo === '睁眼平衡'" :title="'睁眼平衡平均值' + average + 's'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>s</h2>
              <h2 v-show="typeTwo === '闭眼平衡'" :title="'闭眼平衡平均值' + average + 's'">{{typeTwo}}平均值 <em class="font_30">{{average}}</em>s</h2>
            </div>
          </Col>
          <Col span="8">
            <div class="card-module-extra bg3">
              <h2 v-show="typeTwo === '收缩压'" :title="'收缩压中位数' + median + 'mmHg'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmHg</h2>
              <h2 v-show="typeTwo === '舒张压'" :title="'舒张压中位数' + median + 'mmHg'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmHg</h2>
              <h2 v-show="typeTwo === '心率'" :title="'心率中位数' + median + '次/分'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>次/分</h2>
              <h2 v-show="typeTwo === '空腹血糖'" :title="'空腹血糖中位数' + median + 'mmol/L'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '饭后半小时'" :title="'饭后半小时中位数' + median + 'mmol/L'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '饭后1小时'" :title="'饭后1小时中位数' + median + 'mmol/L'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '饭后2小时'" :title="'饭后2小时中位数' + median + 'mmol/L'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '饭后3小时'" :title="'饭后3小时中位数' + median + 'mmol/L'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '尿酸值'" :title="'尿酸值中位数' + median + 'mmol/L'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '总胆固醇'" :title="'总胆固醇中位数' + median + 'mmol/L'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '甘油三脂'" :title="'甘油三脂中位数' + median + 'mmol/L'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '高密度脂蛋白'" :title="'高密度脂蛋白中位数' + median + 'mmol/L'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '低密度脂蛋白'" :title="'低密度脂蛋白中位数' + median + 'mmol/L'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>mmol/L</h2>
              <h2 v-show="typeTwo === '身高'" :title="'身高中位数' + median + 'cm'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>cm</h2>
              <h2 v-show="typeTwo === '腰围'" :title="'腰围中位数' + median + 'cm'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>cm</h2>
              <h2 v-show="typeTwo === '体重'" :title="'体重中位数' + median + 'kg'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>kg</h2>
              <h2 v-show="typeTwo === '体格指数'" :title="'体格指数中位数' + median">{{typeTwo}}中位数 <em class="font_30">{{median}}</em></h2>
              <h2 v-show="typeTwo === '体脂肪率'" :title="'体脂肪率中位数' + median + '%'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>%</h2>
              <h2 v-show="typeTwo === '内脂指数'" :title="'内脂指数中位数' + median">{{typeTwo}}中位数 <em class="font_30">{{median}}</em></h2>
              <h2 v-show="typeTwo === '体水分率'" :title="'体水分率中位数' + median + '%'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>%</h2>
              <h2 v-show="typeTwo === '骨骼肌率'" :title="'骨骼肌率中位数' + median + '%'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>%</h2>
              <h2 v-show="typeTwo === '骨矿含量'" :title="'骨矿含量中位数' + median + '%'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>kg</h2>
              <h2 v-show="typeTwo === '基础代谢'" :title="'基础代谢中位数' + median + 'kcal'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>kcal</h2>
              <h2 v-show="typeTwo === '握力左'" :title="'握力左中位数' + median + 'kg'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>kg</h2>
              <h2 v-show="typeTwo === '握力右'" :title="'握力右中位数' + median + 'kg'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>kg</h2>
              <h2 v-show="typeTwo === '肺活量'" :title="'肺活量中位数' + median + 'ml'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>ml</h2>
              <h2 v-show="typeTwo === '睁眼平衡'" :title="'睁眼平衡中位数' + median + 's'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>s</h2>
              <h2 v-show="typeTwo === '闭眼平衡'" :title="'闭眼平衡中位数' + median + 's'">{{typeTwo}}中位数 <em class="font_30">{{median}}</em>s</h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div class="chart-content">
      <Form :label-width="85" label-position="right">
        <Row>
          <Col span="6">
            <Date-picker @on-change="changeTimeThree" v-model="timeThree" :disabled="isDay" :clearable="false" type="daterange" placeholder="选择日期"></Date-picker>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="">
              <Checkbox v-model="isDay" @on-change="changeStatus">当日统计</Checkbox>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="顾客类型：">
              <Cascader :data="mtypethree" v-model="mtypeArrThree"></Cascader>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="6">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchThree.pid" placeholder="请选择" @on-change="changeProThree" v-else>
                <Option :value="v.id" v-for="v in proListThree" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchThree.cid" placeholder="请选择" @on-change="changeCityThree" v-else>
                <Option :value="v.id" v-for="v in cityListThree" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="6">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchThree.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeListThree" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="2">
            <Button type="primary" class="search-btn" @click="handleThree()">搜索</Button>
          </Col>
        </Row>
      </Form>
      <Row :gutter="40" style="margin-bottom: 20px;">
        <Col span="8">
          <div class="mycard">
            <div class="mycard-title bg7">没有上传血压的人数及百分比</div>
            <Row type="flex" justify="center" align="middle" style="height: 162px;">
              <Col span="12">
                <img src="@/assets/img/icon_blood.png" alt="" class="icon-img">
                <span class="big-member">{{blood_member}}</span>
                <span class="unit-ren">人</span>
              </Col>
              <Col span="12">
                <v-circle
                  :size="130"
                  :trail-width="12"
                  :stroke-width="12"
                  :percent="blood_percent * 100"
                  stroke-color="#f1ce7e">
                  <span class="big-percent color7">{{(blood_percent * 100).toFixed()}}%</span>
                </v-circle>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span="8">
          <div class="mycard">
            <div class="mycard-title bg8">没有上传握力数据的人数及百分比</div>
            <Row type="flex" justify="center" align="middle" style="height: 162px;">
              <Col span="12">
                <img src="@/assets/img/icon_grip.png" alt="" class="icon-img">
                <span class="big-member">{{grip_member}}</span>
                <span class="unit-ren">人</span>
              </Col>
              <Col span="12">
                <v-circle
                  :size="130"
                  :trail-width="12"
                  :stroke-width="12"
                  :percent="grip_percent * 100"
                  stroke-color="#f18985">
                  <span class="big-percent color8">{{(grip_percent * 100).toFixed()}}%</span>
                </v-circle>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span="8">
          <div class="mycard">
            <div class="mycard-title bg9">没有上传血糖的人数及百分比</div>
            <Row type="flex" justify="center" align="middle" style="height: 162px;">
              <Col span="12">
                <img src="@/assets/img/icon_sugar.png" alt="" class="icon-img">
                <span class="big-member">{{sugar_member}}</span>
                <span class="unit-ren">人</span>
              </Col>
              <Col span="12">
                <v-circle
                  :size="130"
                  :trail-width="12"
                  :stroke-width="12"
                  :percent="sugar_percent * 100"
                  stroke-color="#66b0ea">
                  <span class="big-percent color9">{{(sugar_percent * 100).toFixed()}}%</span>
                </v-circle>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row :gutter="40" style="margin-bottom: 20px;">
        <Col span="8">
          <div class="mycard">
            <div class="mycard-title bg10">没有上传肺活量数据的人数及百分比</div>
            <Row type="flex" justify="center" align="middle" style="height: 162px;">
              <Col span="12">
                <img src="@/assets/img/icon_capacity.png" alt="" class="icon-img">
                <span class="big-member">{{capacity_member}}</span>
                <span class="unit-ren">人</span>
              </Col>
              <Col span="12">
                <v-circle
                  :size="130"
                  :trail-width="12"
                  :stroke-width="12"
                  :percent="capacity_percent * 100"
                  stroke-color="#fab2c3">
                  <span class="big-percent color10">{{(capacity_percent * 100).toFixed()}}%</span>
                </v-circle>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span="8">
          <div class="mycard">
            <div class="mycard-title bg11">没有上传人体成分的人数及百分比</div>
            <Row type="flex" justify="center" align="middle" style="height: 162px;">
              <Col span="12">
                <img src="@/assets/img/icon_body.png" alt="" class="icon-img">
                <span class="big-member">{{body_member}}</span>
                <span class="unit-ren">人</span>
              </Col>
              <Col span="12">
                <v-circle
                  :size="130"
                  :trail-width="12"
                  :stroke-width="12"
                  :percent="body_percent * 100"
                  stroke-color="#94e1f0">
                  <span class="big-percent color11">{{(body_percent * 100).toFixed()}}%</span>
                </v-circle>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span="8">
          <div class="mycard">
            <div class="mycard-title bg2">没有上传平衡能力数据的人数及百分比</div>
            <Row type="flex" justify="center" align="middle" style="height: 162px;">
              <Col span="12">
                <img src="@/assets/img/icon_balanced.png" alt="" class="icon-img">
                <span class="big-member">{{balanced_member}}</span>
                <span class="unit-ren">人</span>
              </Col>
              <Col span="12">
                <v-circle
                  :size="130"
                  :trail-width="12"
                  :stroke-width="12"
                  :percent="balanced_percent * 100"
                  stroke-color="#a9d96c">
                  <span class="big-percent color2">{{(balanced_percent * 100).toFixed()}}%</span>
                </v-circle>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';
import vCircle from 'components/circle/circle';
import problemService from '@/services/problemService';
import chartService from '@/services/chartService';
import memberService from '@/services/memberService';
export default {
	components: {
		'v-circle': vCircle,
	},
	data() {
		return {
			searchOne: {
				start_time: '',
				end_time: '',
				grade_id: '',
			},
			searchTwo: {
				start_time: '',
				end_time: '',
				type: '舒张压',
				grade_id: '',
				pid: '',
				cid: '',
				sid: '',
			},
			searchThree: {
				start_time: '',
				end_time: '',
				is_today: 0,
				grade_id: '',
				pid: '',
				cid: '',
				sid: '',
			},
			timeTwo: [Date.now() - 86400000, Date.now()],
			timeThree: [Date.now() - 86400000, Date.now()],
			gradeList: [],
			barChart: '',
			xArr: [],
			yArr: [],
			typeList: [
				{
					name: '收缩压',
				},
				{
					name: '舒张压',
				},
				{
					name: '心率',
				},
				{
					name: '空腹血糖',
				},
				{
					name: '饭后半小时',
				},
				{
					name: '饭后1小时',
				},
				{
					name: '饭后2小时',
				},
				{
					name: '饭后3小时',
				},
				{
					name: '尿酸值',
				},
				{
					name: '总胆固醇',
				},
				{
					name: '甘油三脂',
				},
				{
					name: '高密度脂蛋白',
				},
				{
					name: '低密度脂蛋白',
				},
				{
					name: '身高',
				},
				{
					name: '腰围',
				},
				{
					name: '体重',
				},
				{
					name: '体格指数',
				},
				{
					name: '体脂肪率',
				},
				{
					name: '内脂指数',
				},
				{
					name: '体水分率',
				},
				{
					name: '骨骼肌率',
				},
				{
					name: '骨矿含量',
				},
				{
					name: '基础代谢',
				},
				{
					name: '握力左',
				},
				{
					name: '握力右',
				},
				{
					name: '肺活量',
				},
				{
					name: '闭眼左平衡',
				},
				{
					name: '闭眼右平衡',
				},
			],
			proListTwo: [],
			cityListTwo: [],
			cityTwo: {},
			storeListTwo: [],
			storeTwo: {},
			proListThree: [],
			cityListThree: [],
			cityThree: {},
			storeListThree: [],
			storeThree: {},
			min: '',
			max: '',
			average: '',
			median: '',
			typeTwo: '',
			typeThree: '',
			no_up: '',
			total: '',
			showList: [],
			branchName: '',
			count: '',
			time: '',
			total_use: '',
			isDay: false,
			blood_percent: '',
			blood_member: '',
			grip_percent: '',
			grip_member: '',
			sugar_percent: '',
			sugar_member: '',
			capacity_percent: '',
			capacity_member: '',
			body_percent: '',
			body_member: '',
			balanced_percent: '',
			balanced_member: '',
			power: '',
			mtypeone: [],
			mtypetwo: [],
			mtypethree: [],
			mtypeArrOne: [],
			mtypeArrTwo: [],
			mtypeArrThree: [],
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	watch: {
		mtypeArrOne() {
			this.searchOne.grade_id = this.mtypeArrOne[1];
		},
		mtypeArrTwo() {
			this.searchTwo.grade_id = this.mtypeArrTwo[1];
		},
		mtypeArrThree() {
			this.searchThree.grade_id = this.mtypeArrThree[1];
		},
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchTwo.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchTwo.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchTwo.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
			this.searchThree.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchThree.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchThree.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		problemService.getProblemSelect().then((data) => {
			this.proListTwo = data.branch.pro;
			this.cityTwo = data.branch.city;
			this.storeTwo = data.branch.store;
			this.proListThree = data.branch.pro;
			this.cityThree = data.branch.city;
			this.storeThree = data.branch.store;
		});
		chartService.getGradeList().then((data) => {
			this.gradeList = data.grade;
		});
		memberService.getMemberType().then((data) => {
			this.mtypeone = data;
			this.mtypetwo = data;
			this.mtypethree = data;
		});
		this.searchTwo.start_time = this.filterMMDD(this.timeTwo[0]);
		this.searchTwo.end_time = this.filterMMDD(this.timeTwo[1]);
		this.searchThree.start_time = this.filterMMDD(this.timeThree[0]);
		this.searchThree.end_time = this.filterMMDD(this.timeThree[1]);
		this.getMonitorCondition();
		this.getMonitorBranch();
	},
	mounted() {
		// echarts实例化
		this.barChart = echarts.init(this.$refs.barData);
		// 初始化实例
		let barOptions = {
			title: {
				text: '各门店累积APP和手动上传数据条数',
				textStyle: {
					color: '#424e67',
					fontWeight: 'normal',
					fontSize: 14,
				},
				left: '45%',
				bottom: 40,
			},
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f1ce7e'],
			xAxis: {
				type: 'category',
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: '数据条数',
					type: 'bar',
					data: [],
				},
			],
		};
		this.barChart.setOption(barOptions);
		this.barChart.showLoading();
		this.getChartMonitor();
	},
	methods: {
		changeProTwo() {
			this.cityListTwo = this.cityTwo[this.searchTwo.pid];
			this.searchTwo.cid = '';
		},
		changeCityTwo() {
			this.storeListTwo = this.storeTwo[this.searchTwo.cid];
			this.searchTwo.sid = '';
		},
		changeProThree() {
			this.cityListThree = this.cityThree[this.searchThree.pid];
			this.searchThree.cid = '';
		},
		changeCityThree() {
			this.storeListThree = this.storeThree[this.searchThree.cid];
			this.searchThree.sid = '';
		},
		getChartMonitor() {
			chartService.getChartMonitor(this.searchOne).then((data) => {
				this.xArr = [];
				this.yArr = [];
				data.monitor.forEach((item) => {
					this.xArr.push(item.name);
					this.yArr.push(item.count);
				});
				this.barChart.hideLoading();
				this.barChart.setOption({
					xAxis: {
						data: this.xArr,
					},
					series: [
						{
							data: this.yArr,
						},
					],
				});
			});
		},
		getMonitorCondition() {
			chartService.getMonitorCondition(this.searchTwo).then((data) => {
				this.min = data.min;
				this.max = data.max;
				this.average = data.average;
				this.median = data.median;
				this.typeTwo = this.searchTwo.type;
			});
		},
		getMonitorBranch() {
			chartService.getMonitorBranch(this.searchThree).then((data) => {
				this.blood_percent = data.blood.no_up || 1;
				this.blood_member = data.blood.no_member || 0;
				this.grip_percent = data.grip.no_up || 1;
				this.grip_member = data.grip.no_member || 0;
				this.sugar_percent = data.sugar.no_up || 1;
				this.sugar_member = data.sugar.no_member || 0;
				this.capacity_percent = data.capacity.no_up || 1;
				this.capacity_member = data.capacity.no_member || 0;
				this.body_percent = data.body.no_up || 1;
				this.body_member = data.body.no_member || 0;
				this.balanced_percent = data.balanced.no_up || 1;
				this.balanced_member = data.balanced.no_member || 0;
			});
		},
		changeTimeOne(value) {
			this.searchOne.start_time = value[0];
			this.searchOne.end_time = value[1];
		},
		changeTimeTwo(value) {
			this.searchTwo.start_time = value[0];
			this.searchTwo.end_time = value[1];
		},
		changeTimeThree(value) {
			this.searchThree.start_time = value[0];
			this.searchThree.end_time = value[1];
		},
		changeStatus(value) {
			this.searchThree.is_today = value ? 1 : 0;
		},
		handleOne() {
			this.getChartMonitor();
		},
		handleTwo() {
			this.getMonitorCondition();
		},
		handleThree() {
			this.getMonitorBranch();
		},
		filterMMDD(value) {
			let onedate = new Date(value);
			let strmonth = onedate.getMonth() + 1;
			let strDate = onedate.getDate();
			if (strmonth >= 1 && strmonth <= 9) {
				strmonth = '0' + strmonth;
			}
			if (strDate >= 0 && strDate <= 9) {
				strDate = '0' + strDate;
			}
			return onedate.getFullYear() + '-' + strmonth + '-' + strDate;
		},
	},
};
</script>

<style lang="css" scoped>
.chart-monitor-container .search-one .ivu-form-item {
  margin-bottom: 0;
}
.chart-monitor-container .mycard .big-percent {
  font-size: 28px;
  font-family: Arial;
}
.chart-monitor-container .mycard .big-member {
  font-size: 40px;
  font-family: Arial;
  margin-left: 15px;
  margin-right: 2px;
  word-wrap: break-word;
  line-height: 1;
}
.chart-monitor-container .mycard .icon-img {
  vertical-align: baseline;
  margin-left: 25px;
}
.chart-monitor-container .mycard .unit-ren {
  color: #999;
  font-size: 20px;
}
</style>
